﻿@import "../settings/_settings.scss";

body.masterpage {
    header {
        height: unset;
        .top-bar {
            display: none;
        }
    }

    footer {
        padding-top: 0;
        padding-bottom: 0;
        height: unset;
        box-sizing: border-box;

        &.minimal {
            .page-content {
                display: flex;
                align-items: center;
                height: 80px;
                justify-content: space-between;
            }

            @media screen and (max-width: $tablet-lower-width) {
                img {
                    max-width: 200px;
                }
            }

            .social-media a {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &.brands {
            display: flex;
            align-items: center;
            background-color: $ltgray6;
            height: 50px;

            @media screen and (max-width: $tablet-lower-width) {
                height: unset;
                padding-top: 1em;
                padding-bottom: 1em;
            }

            .brand-logos {
                display: flex;
                flex-wrap: wrap;

                a {
                    display: inline-block;
                }

                @media screen and (max-width: $tablet-lower-width) {
                }
            }
        }
    }

    .main-content {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    // Desktop & Tablet
    @media screen and (min-width:1101px) {
        .page-content {
            margin-left: auto;
            margin-right: auto;
            max-width: 1076px;
            width: 100%;
        }
    }

    @media screen and (min-width:$tablet-lower-width) and (max-width: 1100px) {
        .page-content {
            padding-left: 2em;
            padding-right: 2em;
            width: 100%;
            box-sizing: border-box;
        }
    }

    @media screen and (max-width:$tablet-lower-width) { // Phones
        .page-content {
            margin-left: 5%;
            margin-right: 5%;
        }
    }

    div.cover-photo {
        min-height: 368px;
        background-size: cover;
        background-position-x: center;
        background-position-y: 80%;
        display: flex;

        @media screen and (max-width: $tablet-lower-width) {
            min-height: 200px;
        }

        align-items: flex-end;
        /*background-image: url(/images/runreg_hero@2x.jpg);*/
        /*background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, rgba(48, 62, 72, 0.1)), color-stop(30%, #303e48)), url(../images/laptop_background_wide.jpg);*/
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 30%,rgba(0, 0, 0, 0.3) 70%,rgba(0, 0, 0, 1) 100%), url(/images/runreg_hero@2x.jpg);
    }

    div.cover-logo {
        margin-bottom: 2em;
        color: #fff;
        font-family: $font-muli;
        color: $white;
        font-weight: 900;
        font-size: 30px;
        line-height: 1;
        display: flex;
        align-items: center;

        @media screen and (max-width: $tablet-lower-width) {
            flex-direction: column;
            align-items: flex-start;
            font-size: 22px;
        }

        img {
            margin-right: 1em;
        }
    }
}


.cc-message-container {
    p {
        font-size: 12px;
    }
}
// Top bar navigation, with all reg site images
.top-bar {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  padding: 0 2em;
  height: 41px;
  background-color: $lightGray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $desktop-lower-width) {
    height: 35px;
  }
  .brand-logos {
    display: flex;
    padding: 1em;
  }
  ul {
    padding-inline-start: 0;
    font-family: $font-muli;
    font-size: 14px;
    font-weight: 600;
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-inline-start: 0;
    margin: 0;
    @media screen and (max-width: 1030px) {
      font-size: 12px;
    }
    @media screen and (max-width: 915px) {
      display: none;
    }
    li {
      color: $athleteReg;
      padding: 0 1em;
      text-transform: uppercase;
      letter-spacing: 1;
      display: flex;
      &.phone {
        display: flex;
        align-items: center;
        //Phone icon to the left of the phone number
        &:before {
          content: "";
          display: inline-block;
          background-image: url("../images/Phone_Icon.svg");
          background-repeat: no-repeat;
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
      }
      &:hover {
        color: $black;
      }
    }
  }
  // Hide the menu below iPad
  @media screen and (max-width: $mobile-upper-width) {
    display: none;
  }
}

// Main navigation
.navigation {
    position: fixed;
    z-index: 9999;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    margin: 0 auto;
    background: transparent;
    //There are two top bars in the DOM, this denotes the one positioned for mobile.
    //Not visible unless open class applied with JS.
    .top-bar-nav-mobile {
        display: none;
    }
    // Class added from JS that denotes the mobile menu is open. Styles below are mobile only.
    &.open {
        background: $white;
        height: 100vh;
        width: 100vw;
        box-shadow: none !important; //removes some weird box shadow on the side from the desktop view
        align-items: flex-start; // pins the hamburger and aReg logo to the top when menu is open
        //There are two top bars in the DOM, this denotes the one positioned for mobile.
        // Only visible when the open class is applied to the main nav.
        .top-bar-nav-mobile {
            display: flex;
            flex-direction: column;
            position: absolute;
            margin-top: 317.5px;
            top: 28%;
            left: 50%;
            margin-left: -126.08px;
            padding-left: 0;

            @media screen and (max-width: 485px) {
                top: 20%;
            }

            a {
                width: 100%;

                li {
                    font-family: $font-muli;
                    list-style: none;
                    font-size: 16px;
                    font-weight: 600;
                    color: $black;
                    padding: 0.8em 0;
                    text-transform: uppercase;
                    letter-spacing: 2;
                }
            }

            .phone:before {
                content: "";
                display: inline-block;
                background-image: url("../images/Phone_Icon.svg");
                background-repeat: no-repeat;
                width: 14px;
                height: 14px;
                margin-right: 10px;
            }
        }
    }
    // This styles the hamburger button (which exists outside of 'open')
    button {
        border: none;

        @media screen and (min-width: 769px) {
            display: none; //hide hamburger after iPad.
        }
        // This class is used in the JS function to open the menu (do not rename!)
        .animated-icon1 {
            width: 30px;
            height: 20px;
            position: relative;
            margin: 0px;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            // 3 spans to create the hamburger
            span {
                background: $white;
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: 0.25s ease-in-out;
                -moz-transition: 0.25s ease-in-out;
                -o-transition: 0.25s ease-in-out;
                transition: 0.25s ease-in-out;

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: 10;
                }

                &:nth-child(3) {
                    top: 20;
                }
            }
            // When the mobile menu is open the hamburger animates
            &.open {
                span {
                    background: $athleteReg;

                    &:nth-child(1) {
                        top: 11px;
                        -webkit-transform: rotate(135deg);
                        -moz-transform: rotate(135deg);
                        -o-transform: rotate(135deg);
                        transform: rotate(135deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                        left: -60px;
                    }

                    &:nth-child(3) {
                        top: 11px;
                        -webkit-transform: rotate(-135deg);
                        -moz-transform: rotate(-135deg);
                        -o-transform: rotate(-135deg);
                        transform: rotate(-135deg);
                    }
                }
            }
        }
    }
    // Class added via JS. Changes the background color of the nav menu on scroll
    // Works on mobile + desktop
    &.nav-scroll {
        background: $white !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
        transition: all 200ms ease-in-out;

        @media screen and (max-width: $tablet-lower-width) {
            background: $athleteReg !important;
        }

        &.open {
            background: $white !important; // forces background back to white on mobile when menu is open
        }

        .main-nav-list {
            li {
                a {
                    color: $athleteReg;

                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
    // Scaling athleteReg logo in Nav based on screen size
    a {
        &.aRegLogo {
            display: none;

            @media screen and (max-width: $desktop-lower-width) {
                width: 20%; //Shrinking between iPad and iPad Pro to fit all nav items
            }

            @media screen and (max-width: $tablet-lower-width) {
                width: 30%;
            }

            @media screen and (max-width: $mobile-upper-width) {
                width: 35%;
            }

            @media screen and (max-width: 480px) {
                width: 45%;
            }
            // toggle classes to show/hide between black/white athleteReg logo.
            &.show {
                display: block;
            }

            img {
                @media screen and (max-width: $desktop-lower-width) {
                    width: 100%;
                }
            }
        }
    }
    // Main nav when not scrolled (default behavior)
    .main-nav-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: $font-muli;
        font-size: 18px;
        font-weight: 400;
        padding-inline-start: 0; // removes default ul padding
        margin: 0;

        @media screen and (max-width: 1230px) {
            font-size: 16px;
        }

        @media screen and (max-width: 821px) {
            font-size: 13px;
        }

        @media screen and (max-width: $mobile-upper-width) {
            font-size: 14px;
        }

        @media screen and (max-width: $tablet-lower-width) {
            display: none; // hides the menu on mobile until hamburger is clicked
        }
        // Mobile
        &.open {
            display: flex !important; // Forces display on mobile open
            flex-direction: column;
            font-family: $font-muli;
            font-size: 25px;
            font-weight: 900;
            position: absolute;
            top: 20%; //iPad top position
            left: 50%; //center when position: absolute. part 1
            margin-left: -126.08px; //center when position: absolute. part 2 (half of the element width)
            padding-inline-start: 0px;
            padding-left: 0;
            margin-block-start: 1em;
            margin-block-end: 1em;
            padding-bottom: 1.5em;
            border-bottom: 1px solid $accent;

            @media screen and (max-width: 485px) {
                top: 10%;
            }

            li {
                padding: 0.5em 0;
                margin: 0;
            }
        }

        li {
            list-style: none;
            font-family: $font-muli;
            padding: 0.5em 0.5em;
            margin: 0 0.5em;
            border-bottom: 3px solid transparent;
            //adding a highlight to the menu items when it's over it's corresponding section
            &.active {
                transition: all 300ms ease-in;
                border-bottom: 3px solid $runRegGreen;
                //different highlight on mobile
                @media screen and (max-width: $tablet-lower-width) {
                    border-bottom: none;
                    margin-left: -32px;

                    &:before {
                        content: "";
                        display: inline-block;
                        background-image: url("../images/Mobile_Active.svg");
                        width: 24px;
                        height: 23.35px;
                    }
                }
            }

            @media screen and (max-width: $desktop-lower-width) {
                padding: 0 0.5em;
            }

            a {
                color: $white;
                text-transform: capitalize;
                text-decoration: none;

                &:hover {
                    color: $accent;
                }

                @media screen and (max-width: $tablet-lower-width) {
                    color: $athleteReg;
                }
            }
        }
    }
}

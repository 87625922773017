#outside-banner {
    z-index:999999;
}

header {
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
    // @media screen and (min-width: $desktop-middle-width2) {
    //     height: 100vh;
    // }
    // @media screen and (min-width: 769px) {
    //     height: 100vh;
    // }
    @media screen and (max-width: $desktop-lower-width) {
        height: 40vh;
    }

    @media screen and (max-width: $tablet-lower-width) {
        height: 50vh;
    }

    @media screen and (max-width: $mobile-upper-width) {
        height: 100vh;
    }

    .video-container {
        position: relative;
        margin-top: 40px;
        height: 80vh;

        @media screen and (max-width: $desktop-lower-width) {
            height: 50vh;
            margin-top: 35px;
        }

        @media screen and (max-width: $tablet-lower-width) {
            height: 50vh;
            margin-top: 35px;
        }

        @media screen and (max-width: $mobile-upper-width) {
            height: 80vh;
            margin-top: 0;
        }

        video {
            height: 100%;
            z-index: -1;
            width: 100%;
            object-fit: cover;
            /*Hack for Edge/IE browser*/
            @supports (-ms-ime-align:auto) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
            }

            @supports (-ms-accelerator:true) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
            }

            @media all and (-ms-high-contrast:none) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
            }

            @media screen and (max-width: $mobile-upper-width) {
                display: none;
            }
        }

        .mobile-header {
            height: 100%;
            z-index: -1;
            width: 100%;
            object-fit: cover;
            display: none;

            @media screen and (max-width: $mobile-upper-width) {
                display: block;
            }
        }
    }

    .hero-row {
        position: absolute;
        display: flex;
        z-index: 1;
        justify-content: space-between;
        flex-direction: row;
        bottom: -15%;


        &.desktop {
            @media screen and (max-width: $mobile-upper-width) {
                display: none;
            }

            &.text {
                bottom: 0;

                @media screen and (max-width: $desktop-lower-width) {
                    bottom: 10%;
                }

                @media screen and (max-width: $tablet-lower-width) {
                    bottom: -10%;
                }
            }

            &.phone {
                justify-content: flex-end;

                @media screen and (max-width: $desktop-lower-width) {
                    bottom: 10%;
                }

                @media screen and (max-width: $tablet-lower-width) {
                    bottom: -10%;
                }
            }
        }

        &.mobile {
            display: none;

            @media screen and (max-width: $mobile-upper-width) {
                display: flex;
                padding-top: 2rem;
            }
        }

        @media screen and (min-width: $mobile-lower-width) {
            left: 9%;
        }

        @media screen and (min-width: $mobile-upper-width) {
            left: 7.5%;
        }

        @media screen and (min-width: $desktop-middle-width) {
            left: 8.5%;
        }

        @media screen and (min-width: $desktop-middle-upper-width) {
            left: 10.5%;
        }

        @media screen and (min-width: $desktop-upper-width) {
            left: 15%;
        }

        @media screen and (max-width: $mobile-upper-width) {
            flex-direction: column;
        }

        .hero-text {
            position: relative;
            color: $white;
            height: 100%;
            padding-right: 1rem;
            padding-bottom: 6rem;

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
                padding-bottom: 0;
            }

            @media screen and (min-width: $mobile-upper-width) {
                width: 53%;
            }

            @media screen and (min-width: $desktop-lower-width) {
                width: 60%;
            }

            .hero-blobs {
                position: absolute;
                z-index: -1;
                left: -10%;
                top: -19%;
                width: 35%;
                height: auto;

                @media screen and (max-width: $tablet-lower-width) {
                    top: -10%;
                    width: 45%;
                }

                @media screen and (max-width: $mobile-upper-width) {
                    top: -15%;
                    width: 55%;
                }


                img {
                    width: 100%;
                }
            }

            h1 {
                margin: 0.5em 0;

                @media screen and (max-width: $tablet-upper-width) {
                    max-width: 323px;
                }

                @media screen and (max-width: $mobile-upper-width) {
                    max-width: 100%;
                }
            }

            p {
                @media screen and (min-width: $desktop-lower-width) {
                    max-width: 600px;
                }

                @media screen and (min-width: $desktop-middle-width) {
                    max-width: 600px;
                }

                @media screen and (min-width: $desktop-middle-upper-width) {
                    max-width: 700px;
                }

                @media screen and (min-width: $desktop-upper-width) {
                    max-width: 800px;
                }
            }
        }

        .hero-phones {
            z-index: 2;

            @media screen and (min-width: 769px) {
                opacity: 0;
                transition: opacity 300ms ease-in;
                width: 40%;

                &.show-phones {
                    opacity: 1;
                    transition: opacity 300ms ease-in;
                }
            }

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
                margin-top: 0.5rem;
            }

            @media screen and (max-width: $mobile-upper-width) {
                margin-top: 0.5rem;
            }

            @media screen and (min-width: $mobile-upper-width) {
                width: 47%;
            }

            @media screen and (min-width: $desktop-lower-width) {
                width: 40%;
                margin-bottom: 0px;
                margin-top: 0px;
            }

            > img {
                width: 100%;
                height: auto;
            }
        }
    }

    .hero-scroll-down {
        z-index: 3;
        display: flex;
        align-items: center;
        background-color: $white;
        height: 15vh;

        @media screen and (max-width: $desktop-lower-width) {
            display: none;
        }

        .z-override {
            z-index: 1 !important;
        }
    }
}

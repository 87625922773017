/* Color Variables ----------------------------------------------- */
/* Global */
$white: #ffffff;
$ltgray: #EFEFEF; // Background
$ltgray2: #C4C4C4; // Disabled Text
$ltgray3: #525252; // Text
$font: $ltgray3;
$ltgray4: #F8F8F8; // Table Fill
$ltgray5: #B2B2B2; // Border
$ltgray6: #F2F2F2; // Nav hover
$ltgray7: #707070; // Table border
$ltgray8: #95989A; // Nav border
$ltgray9: #B4B4B4; // Check Border
$ltgray10: #D0D0D0; // Contact Us Border
$ltgray11: #818181;
$ltgray12: #EBEBE4; // Disabled Input Background
$ltgray13: #545454; // Disabled Input Text
$nearblack: #3f403d;
$black: #000000;
$yellow: #D6B40A;

/* Outside */
$outside-yellow: #FFCF39;
$light-outside-yellow: #FFF5D7;
$dark-outside-yellow: #D2A940;
$outside-dkgray: #333333;
$outside-red: #951c00;

/* Menus */
$ftgray: #ececec; // Sidebar Content Background
$menubackground: #151C20; //#1a2125;
$ltgray6: #F2F2F2; // Nav hover
$ltgray7: #707070; // Table border

/* Tables */
$dkgray: #303e48; // Gray Header Text/Sub Header Background
$dkgray3: #C1CAD0; // Table Header Background
$coolgray: #c1cad0; // Table Header Background
$ltgray4: #f8f8f8; // Table Background Fill
$ltgray14: #E0E0E0; // Total Row/Footer

/* Director Tasks */
$ftgray: #ececec; // Sidebar Content Text
$dkgray2: #62686c; // Sidebar Navigation
$ltgray8: #95989a; // Sidebar Expanded Navigation

/* Input */
$GrayButton: #e0e0e0;
$GrayButtonHover: #d3d3d3;
$green: #94D60A; // Strictly Green
$green2: #18a31a; // Green Button
$green3: #35af37; // Green Hover
$dkgreen: #6c960b; // Green Text Links
$blue: #0e4da7; // BikeReg Blue
$blueA25: rgba( 14, 77, 167, .25); // Bike Reg Blue A: .25
$ltblue: #e1e8ed; // Blue for Tabs
$blue2: #1356b5; // Blue for Button hover
$blue3: #1e60be; // Blue for Button hover border
$bluegray: #e4e8eb; // Hover Highlight
$bluetexthover: #00627d;

/* Links */
$bluealt: #0E4DA7;
$link: $blue;
$hover: $blue2;
$active: $bluealt;
$hyperlink-weight: 600;

/* Warnings */
$red: #ec0b43; // Closed Events
$orange: #ff6300; // Warning Feedback
$feedback: $orange;
$ltorange: #e2ac98;
$pink: #ffcece; // Background of warning banners

/* Site Specific Accent */
$accent: #94d60a; // BikeReg, RunReg Accent
$accent2: #94D60A;
$SkiRegBlue: #67a2b9; // SkiReg Accent
$TriRegPink: #ec0044; // TriReg Accent
$pledgereg: rgba(254, 80, 0, 1);

/* Font */
$font-sans: "Open Sans", arial, helvetica, sans-serif;
$font-muli: "Muli", "trebuchet ms", arial, helvetica, sans-serif;
$italic: normal;

/* CDN */
$bikeregCDN: "https://d36gb93zszu20a.cloudfront.net";
$runregCDN: "https://dapjneu5elj1q.cloudfront.net";
$skiregCDN: "https://d12joqltwm6vxx.cloudfront.net";
$triRegCDN: "https://d2l4puij73vem3.cloudfront.net";

/* View Variables */
$mobile-lower-width: 320px;
$mobile-mid-width: 440px;
$mobile-upper-width: 767px;
$tablet-lower-width: 768px;
$tablet-upper-width: 1023px;
$desktop-lower-width: 1024px; // Including iPad Pro
$desktop-middle-width: 1280px;
$desktop-middle-upper-width: 1600px;
$desktop-upper-width: 1920px;

$modal-dialog-width: 838px; 
$modal-content-width: 998px;

/* Baseline Z-Index Values */
$z-neutral: 0;
$z-high: 100;
$z-higher: 1000;
$z-highest: 10000;
$z-lower: -1000;
$z-lowest: -10000;
/* CSS Variables ------------------------------------------- */
/*
    // Getting an existing CSS variable. e.g.
    let htmlStyles = window.getComputedStyle(document.querySelector("html"));
    let columnNumber = parseInt(htmlStyles.getPropertyValue("--columnNumber"));

    // Setting an existing CSS variable. e.g.
    var columnNumber = parseInt(c); // c = Count the number of would be subheaders. e.g. First, last, Team = 3.
    document.documentElement.style.setProperty("--columnNumber", columnNumber);
*/

:root {
    --rowNumber: 0; // Placeholder; update on load.
    --columnCount: 0; // Placeholder; update on load.
    --gridHeight: 0px; // Placeholder; update on load.
    /* These can be accessed via JavaScript */
    --mobile-lower-width: $mobile-lower-width;
    --mobile-upper-width: $mobile-upper-width;
    --tablet-lower-width: $tablet-lower-width;
    --tablet-upper-width: $tablet-upper-width;
    --desktop-lower-width: $desktop-lower-width;
    --desktop-middle-width: $desktop-middle-width;
}

/* Mixin Variables ----------------------------------------------- */

/* Fluid Type */
$font-offset: 2px; // The difference between our desired size and how low it's allowed to drop.
// e.g. 16px is only allowed to drop to 14px, and no further. Works with negative values, too.

$min-scale-width: 320px; // Viewport size at which we stop scaling down. (iPhone 5/SE)
$max-scale-width: 1280px; // Viewport size at which we stop scaling up. (Low Range Desktop) 

/* homepage gradients*/
$homepage-tablet-gradient: linear-gradient(to bottom, rgba(0,0,0,0.0),rgba(0,0,0,0.0) 700px,rgba(0,0,0,1.0) 800px);
$homepage-desktop-gradient: linear-gradient(to right, rgba(0,0,0,0.8),rgba(0,0,0,0.6) 37%,rgba(0,0,0,0.0) 75%);
$homepage-mobile-gradient: linear-gradient(to bottom, rgba(0,0,0,0.0),rgba(0,0,0,0.0) 345px,rgba(0,0,0,1.0) 385px);
$menu-gradient: linear-gradient(to bottom, rgba(0,0,0,0.8),rgba(0,0,0,0.0) 100px);
$hero-background: #121210;

$virtual-desktop-gradient: linear-gradient(to right, rgba(0,0,0,0.6),rgba(0,0,0,0.4) 37%,rgba(0,0,0,0.0) 75%);
.carousel-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    max-width: 100%;
    margin-left: 7.5%;
    box-sizing: border-box;
    scroll-behavior: smooth;
    transition: all 300ms ease-in-out;

    @media screen and (min-width: $mobile-lower-width) {
        margin-left: 9%;
    }

    @media screen and (min-width: $mobile-upper-width) {
        margin-left: 7.5%;
    }

    @media screen and (min-width: $desktop-middle-width) {
        margin-left: 8.5%;
    }

    @media screen and (min-width: $desktop-middle-upper-width) {
        margin-left: 10.5%;
    }

    @media screen and (min-width: $desktop-upper-width) {
        margin-left: 15%;
        padding-right: 20em;
    }


    &.margin-left-zero {
        margin-left: 0;

        @media screen and (min-width: $mobile-lower-width) {
            margin-left: 0;
        }

        @media screen and (min-width: $mobile-upper-width) {
            margin-left: 0;
        }

        @media screen and (min-width: $desktop-middle-width) {
            margin-left: 0;
        }
    }

    &.margin-left-minus {
        margin-left: -7.5%;

        @media screen and (min-width: $mobile-lower-width) {
            margin-left: -9%;
        }

        @media screen and (min-width: $mobile-upper-width) {
            margin-left: -7.5%;
        }

        @media screen and (min-width: $desktop-middle-width) {
            margin-left: -8.5%;
        }
    }

    .brand-container {
        position: relative;
        display: block;
        padding: 1em 1em 0 1em;
        transition: all 200ms ease-in-out;

        @media screen and (max-width: $mobile-upper-width) {
            text-align: center;
        }

        &:first-child {
            padding-left: 0;

            @media screen and (max-width: $mobile-upper-width) {
                padding-left: 0;
            }
        }

        &:nth-child(5) {
            padding-right: 0;

            @media screen and (max-width: $mobile-upper-width) {
                padding-right: 0;
            }
        }

        &.opacity {
            opacity: 0.4;
        }

        a {
            .carousel-image-container {
                position: relative;

                .brand {
                    position: absolute;
                    left: 0;
                    width: 60%;
                    top: 12%;
                    height: auto;
                    z-index: 2;
                }

                .background-container {
                    transform: skewX(-10deg) scale(0.9);
                    border-radius: 35px;
                    overflow: hidden;

                    img {
                        transition: all 200ms ease-in-out;
                        transform: skewX(11deg) translateX(0px) scale(1.13) translateZ(0);
                        height: 220px;
                        width: auto;
                        -webkit-backface-visibility: hidden;
                        -ms-transform: translateZ(0); /* IE 9 */
                        @media screen and (max-width: $desktop-middle-width) {
                            height: 220px;
                        }

                        @media screen and (max-width: $desktop-lower-width) {
                            height: 185px;
                        }

                        @media screen and (max-width: 370px) {
                            height: 165px;
                        }

                        @media screen and (min-width: 1281px) {
                            height: 220px;
                        }

                        &:hover {
                            transform: scale(1.23) skewX(11deg) translateX(0px);
                            overflow: auto;
                        }
                    }
                }
            }
        }

        .p-small {
            padding: 1em 1em 1em 0;
        }
    }

    .carousel-arrow {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        bottom: 35%;

        @media screen and (max-width: 468px) {
            width: 63px;
        }

        &.arrow-right {
            right: 0;
        }

        &.arrow-left {
            left: 0;
            transform: rotate(180deg);
            visibility: hidden;
        }
    }
}

.carousel-row-slide {
    display: none;
    align-items: center;
    justify-content: flex-start;
    width: calc(var(--n)*350px);
    margin-left: 7.5%;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
    transform: translate(calc(var(--i, 0)/var(--n)*-80%));
    scroll-behavior: smooth;
    transition: all 300ms ease-in-out;
    --n: 5;

    @media screen and (min-width: $mobile-lower-width) {
        margin-left: 9%;
    }

    @media screen and (min-width: $mobile-upper-width) {
        margin-left: 7.5%;
    }

    @media screen and (min-width: $desktop-middle-width) {
        margin-left: 8.5%;
    }

    @media screen and (min-width: $desktop-middle-upper-width) {
        left: 10.5%;
    }

    @media screen and (min-width: $desktop-upper-width) {
        left: 15%;
    }

    .brand-container {
        /*min-width: 100%;*/
        width: 350px;
        /*width: calc(100%/var(--n));*/
        pointer-events: none;
        padding: 1.5em 1.5em 0 1.5em;
        transition: all 200ms ease-in-out;

            @media screen and (max-width: $mobile-upper-width) {
                text-align: center;
            }

            &:first-child {
                padding-left: 1.5em;

                @media screen and (max-width: $mobile-upper-width) {
                    padding-left: 0;
                }
            }

            &:nth-child(5) {
                padding-right: 1.5em;

                @media screen and (max-width: $mobile-upper-width) {
                    padding-right: 0;
                }
            }

            &.opacity {
                opacity: 0.4;
            }

            a {
                .carousel-image-container {
                    position: relative;

                    .brand {
                        position: absolute;
                        left: 0;
                        width: 60%;
                        top: 12%;
                        height: auto;
                        z-index: 2;
                    }

                    .background-container {


                        img {
                            transition: all 200ms ease-in-out;
                            height: 220px;
                            width: auto;

                            @media screen and (max-width: $desktop-middle-width) {
                                height: 220px;
                            }

                            @media screen and (max-width: $desktop-lower-width) {
                                height: 185px;
                            }

                            @media screen and (max-width: 370px) {
                                height: 165px;
                            }

                            @media screen and (min-width: 1281px) {
                                height: 220px;
                            }

                            &:hover {
                                transform: scale(1.1);
                                overflow: auto;
                            }
                        }
                    }
                }
            }

            .p-small {
                padding: 1em 1em 1em 0;
            }
        }

        .carousel-arrow {
            position: absolute;
            z-index: 2;
            cursor: pointer;
            bottom: 35%;

            @media screen and (max-width: 468px) {
                width: 63px;
            }

            &.arrow-right {
                right: 0;
            }

            &.arrow-left {
                left: 0;
                transform: rotate(180deg);
                visibility: hidden;
            }
        }
    }

﻿@import "../settings/_settings.scss";

body {

    .checkbox {
        display: flex;

        input {
            -webkit-appearance: none;
            border: 1px solid $ltgray2;
            padding: 9px;
            border-radius: 3px;
            position: absolute;
        }

        input:checked {
            background-color: $blue2;
        }

        input:checked:after {
            content: '\2714\fe0e';
            font-size: 14px;
            position: absolute;
            top: -1px;
            left: 3px;
            color: $white;
        }

        label {
            margin-left: 40px;
        }
    }

    .textbox {
        font: normal 1em "Open Sans",arial,helvetica,sans-serif;
        border: 2px solid $ltgray2;
        box-sizing: border-box;
        border-radius: 5px 5px 5px 5px;
        width: 100%;
        margin: 0.5em auto;
        padding: .75em;
        font-size: 16px;

        &:focus {
            border: 2px solid $blue;
        }
    }
}

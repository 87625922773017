
.container {
    width: 85%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: $mobile-lower-width) {
        width: 82%;
    }

    @media screen and (min-width: $mobile-upper-width) {
        width: 85%;
    }

    @media screen and (min-width: $desktop-middle-width) {
        width: 83%;
    }

    @media screen and (min-width: $desktop-middle-upper-width) {
        width: 79%;
    }

    @media screen and (min-width: $desktop-upper-width) {
        width: 70%;
    }
}

section {
    padding: 10rem 0;

    @media screen and (max-width: $mobile-upper-width) {
        padding: 3rem 0;
    }

    @media screen and (max-width: $desktop-lower-width) {
        padding: 4rem 0;
    }

    &#our-brands {
        background-color: $white;
        padding-top: 8rem;
        position: relative;

        .flex-header {
            width: 35%;

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
            }
        }

        .flex-paragraph {
            width: 65%;
            padding-left: 5%;
            margin: 2rem 0;

            @media screen and (max-width: $tablet-lower-width) {
                margin: 1.5rem 0;
            }

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
                padding-left: 0%;
                margin-top: 0;
            }
        }

        @media screen and (max-width: $desktop-lower-width) {
            padding: 6rem 0;
        }

        @media screen and (max-width: $mobile-upper-width) {
            padding: 3rem 0;
        }
    }

    &#fundraising {
        .testimonials-desktop {
            display: block;
            padding-top: 2rem;
            margin-top: 2rem;
            border-top: 1px solid $accent;

            @media screen and (max-width: $mobile-upper-width) {
                position: relative;
                width: auto;
            }

            @media screen and (max-width: $desktop-lower-width) {
                display: none;
            }
        }

        .testimonials {
            display: flex;
            width: 100%;
            font-weight: 900;
            align-items: center;
            color: $athleteReg;
            margin: 2rem 0;

            img {
                width: 50px;
                height: 50px;
                margin-right: 1rem;
            }

            p {
                font-size: 15px;
            }

            p:nth-child(1) {
                margin-bottom: 0;
            }

            p:nth-child(2) {
                margin-top: 0;
            }
        }

        .testimonials-mobile {
            display: none;



            @media screen and (max-width: $tablet-upper-width) {
                display: block;
                border-top: 1px solid #c1cad0;
                padding-top: 2rem;
                margin-top: 2rem;


                .testimonials {
                    display: flex;
                    width: 100%;
                    font-weight: 900;
                    align-items: center;
                    color: $athleteReg;
                    margin: 2rem 0;

                    img {
                        width: 50px;
                        height: 50px;
                        margin-right: 1rem;
                    }

                    p {
                        font-size: 15px;
                    }

                    p:nth-child(1) {
                        margin-bottom: 0;
                    }

                    p:nth-child(2) {
                        margin-top: 0;
                    }
                }
            }

            @media screen and (max-width: $mobile-upper-width) {
                display: block;
                border-top: none;
                padding-top: 0;
                margin-top: 0;
            }
        }



        .button-link {
            margin-top: 1rem;
            font-size: 16px;

            &:hover {
                text-decoration: underline;
            }
        }

        .column-image {
            object-fit: contain;
        }

        .pledge-phone {
            position: relative;
            background-image: url('../images/pledgereg_bkgrd.png');
            background-repeat: no-repeat;
            background-size: contain;
            margin-top: 6rem;
            width: 40%;
            height: 40%;
            margin-left: 3rem;

            @media screen and (max-width: $mobile-upper-width) {
                width: 90%;
                height: auto;
                margin-left: 0;
                margin: 5rem auto;
            }

            .blue-callout {
                position: absolute;
                top: -7%;
                left: 8%;
                width: 25%;

                &.animate {
                    animation-name: animate-blue;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: backwards;
                    animation-play-state: paused;

                    @keyframes animate-blue {
                        from {
                            top: -30%;
                            left: 10%;
                        }

                        to {
                            top: -7%;
                            left: 8%;
                        }
                    }
                }
            }

            .orange-callout {
                position: absolute;
                top: -10%;
                left: -4%;
                width: 12.9%;

                &.animate {
                    animation-name: animate-orange;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: backwards;
                    animation-play-state: paused;

                    @keyframes animate-orange {
                        from {
                            top: -32%;
                            left: -25%;
                        }

                        to {
                            top: -10%;
                            left: -4%;
                        }
                    }
                }
            }

            .green-callout {
                position: absolute;
                top: 13%;
                left: 2%;
                width: 16.07%;

                &.animate {
                    animation-name: animate-green;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: backwards;
                    animation-play-state: paused;

                    @keyframes animate-green {
                        from {
                            top: 10%;
                            left: -20%;
                        }

                        to {
                            top: 13%;
                            left: 2%;
                        }
                    }
                }
            }

            .lightblue-callout {
                position: absolute;
                bottom: -8%;
                right: 3%;
                width: 22.62%;

                &.animate {
                    animation-name: animate-lightblue;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: backwards;
                    animation-play-state: paused;

                    @keyframes animate-lightblue {
                        from {
                            bottom: -20%;
                            right: 0%;
                        }

                        to {
                            bottom: -8%;
                            right: 3%;
                        }
                    }
                }
            }

            .pink-callout {
                position: absolute;
                bottom: 8%;
                right: -7%;
                width: 14.29%;

                &.animate {
                    animation-name: animate-pink;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: backwards;
                    animation-play-state: paused; //This allows us to use a function that detects user scroll to turn the animation on and off
                    @keyframes animate-pink {
                        from {
                            bottom: 20%;
                            right: -20%;
                        }

                        to {
                            bottom: 8%;
                            right: -7%;
                        }
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }

    &#manage-event {
        color: $white;
        background: url('../images/laptop_background_wide.jpg');
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;

        @media screen and (max-width: $mobile-upper-width) {
            padding-top: 16rem;
            background: linear-gradient(to bottom, rgba(48, 62, 72, .1) 10%, rgba(48, 62, 72, 1) 30%), url(../images/laptop_background_wide.jpg);
            background-size: 150%;
            background-position: top right;
        }

        .flex-manage {
            width: 40%;

            @media screen and (max-width: $desktop-middle-width) {
                width: 45%;
            }

            @media screen and (max-width: $desktop-lower-width) {
                width: 50%;
            }

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
            }
        }

        .button-container {
            margin-top: 2rem;
        }
    }

    &#participant-services {
        background: linear-gradient(to right, rgba(247, 191, 140, 0.22), rgba(181, 175, 177, 0.22), rgba(77, 114, 124, 0.22));

        .services-block {
            display: flex;
            justify-items: stretch;
            width: 100%;

            @media screen and (max-width: $mobile-upper-width) {
                display: none;
            }

            .services-callout {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: self-start;
                width: 20%;

                @media screen and (max-width: $mobile-upper-width) {
                    width: 100%;
                }

                @media screen and (max-width: $desktop-middle-width) {
                    width: 25%;
                }

                .callout-block {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    align-items: center;
                    justify-content: space-around;
                    background-color: $white;
                    opacity: 0.7;
                    padding: 0rem 1.5rem;
                    width: 100%;
                    height: 100%;
                    margin: .3em -.6em;
                    /*@media screen and (max-width: $desktop-middle-upper-width) {
                        padding: 2.4rem 1rem;
                    }

                    @media screen and (max-width: $desktop-lower-width) {
                        padding: 1.6rem;
                    }

                    @media screen and (max-width: $tablet-middle-width) {
                        padding: 1.3rem;
                    }*/
                    img {
                        width: 100%;

                        @media screen and (max-width: $mobile-upper-width) {
                            width: 70%;
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.active-block {
                        opacity: 1;
                        width: 107%;
                        box-shadow: 0px 13px 15px $SixteenPercentBlack;
                    }
                }
            }

            .services-section {
                display: none;

                &.show-flex {
                    position: relative;
                    display: flex;
                    width: 80%;
                    // pseudoclass allows the box-shadow to remain under the brand tags on the side.
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: -1;
                        box-shadow: 0px 13px 15px $SixteenPercentBlack;
                    }
                }
            }

            .services-description {
                padding: 2rem 3rem;
                background-color: $white;
                width: 55%;

                @media screen and (max-width: $desktop-lower-width) {
                    padding: 1rem;
                }

                @media screen and (max-width: $tablet-lower-width) {
                    padding: 1rem;
                }

                @media screen and (max-width: $mobile-upper-width) {
                    padding: 1rem;
                    width: 100%;
                }

                p {
                    font-family: $font-muli;
                    font-size: 20px;
                    font-weight: 900;
                    letter-spacing: 1.9;
                    border-bottom: 1px solid $SixteenPercentBlack;
                    padding-bottom: 0.5rem;

                    @media screen and (max-width: $desktop-middle-upper-width) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: $desktop-middle-width) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $desktop-lower-width) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $tablet-middle-width) {
                        font-size: 9px;
                    }

                    @media screen and (max-width: $mobile-upper-width) {
                        font-size: 11px;
                    }
                }

                ul {
                    list-style: disc;
                    font-size: 24px;
                    margin-left: -1rem;

                    @media screen and (max-width: $desktop-middle-upper-width) {
                        font-size: 18px;
                    }

                    @media screen and (max-width: $desktop-middle-width) {
                        font-size: 15px;
                    }

                    @media screen and (max-width: $desktop-lower-width) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: $tablet-middle-width) {
                        font-size: 11.5px;
                    }

                    @media screen and (max-width: $mobile-upper-width) {
                        font-size: 13px;
                    }

                    li {
                        margin: 0.3em 0;
                    }
                }
            }

            .services-image {
                position: relative;
                width: 45%;
                height: auto;
                /*@media screen and (max-width: $desktop-upper-width) {
                    width: 35%;
                }

                @media screen and (max-width: $desktop-middle-upper-width) {
                    width: 40%;
                }

                @media screen and (max-width: $desktop-middle-width) {
                    width: 40%;
                }

                @media screen and (max-width: $tablet-middle-width) {
                    width: 40%
                }*/
                @media screen and (max-width: $mobile-upper-width) {
                    width: 100%;
                }



                .image {
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        transition: transform 300ms ease-in-out;

                        &.scale {
                            transform: scale(1.1);
                        }

                        /*&:hover {
                            transform: scale(1.1);
                        }*/
                        /*@media screen and (max-width: $desktop-middle-upper-width) {
                            height: 490px;
                        }

                        @media screen and (max-width: $desktop-lower-width) {
                            height: 380px;
                        }

                        @media screen and (max-width: $tablet-middle-width) {
                            height: 350px;
                        }

                        @media screen and (max-width: $mobile-upper-width) {
                            height: 180px;
                        }*/
                    }
                }

                .image-buttons {
                    display: flex;
                    align-items: flex-end;
                    position: absolute;
                    bottom: 0;
                    height: 50%;
                    width: 100%;
                    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));

                    p {
                        font-size: 18px;
                        color: $white;
                        margin: 0 0 2rem 2rem;

                        @media screen and (max-width: $desktop-lower-width) {
                            font-size: 14px;
                            width: 5rem;
                            overflow-wrap: break-word;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            right: 2rem;
                            bottom: 2rem;
                            background-image: url('../images/circle_arrow.svg');
                            color: $white;
                            width: 36px;
                            height: 36px;

                            @media screen and (max-width: $desktop-lower-width) {
                                bottom: 2.5rem;
                            }

                            @media screen and (max-width: $mobile-upper-width) {
                                display: none;
                            }
                        }
                    }
                }
            }

            .show-flex {
                display: flex;
            }

            .hide-flex {
                display: none;
            }
        }

        .services-block-mobile {
            display: none;

            @media screen and (max-width: $mobile-upper-width) {
                display: flex;
                width: 100%;

                .services-callout {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;

                    .callout-block {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        background-color: $white;
                        color: $darkBorder;
                        width: 100%;
                        margin: 0.5rem 0;
                        padding: 1.4rem;

                        img {
                            width: 60%;
                        }

                        .add {
                            font-size: 32px;
                            display: block;
                        }

                        .arrow {
                            display: none;
                        }

                        &.active-block {
                            .arrow {
                                display: block;
                                font-size: 32px;
                            }

                            .add {
                                display: none;
                            }
                        }


                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &.active-block {
                            width: 100%;
                            box-shadow: 0px 13px 15px $SixteenPercentBlack;
                        }
                    }
                }

                .services-wrapper {
                    .services-section {
                        display: flex;
                        flex-direction: column;
                        max-height: 0px;
                        overflow: hidden;
                        transition: max-height 400ms ease;
                        margin-top: -1rem;

                        &.show-flex {
                            max-height: 700px;
                            transition: max-height 400ms ease;
                        }
                    }
                }

                .services-description {
                    padding: 1rem;
                    background-color: $white;
                    width: 100%;

                    p {
                        font-family: $font-muli;
                        font-size: 11px;
                        font-weight: 900;
                        letter-spacing: 1.9;
                        border-bottom: 1px solid $SixteenPercentBlack;
                        padding-bottom: 0.5rem;
                    }

                    ul {
                        list-style: disc;
                        font-size: 13px;
                        margin-left: -1rem;

                        li {
                            margin: 0.3rem 0;
                        }
                    }
                }

                .services-image {
                    position: relative;
                    width: 100%;
                    height: auto;


                    .image {
                        img {
                            object-fit: cover;
                            height: 180px;
                            width: 100%;
                        }
                    }

                    .image-buttons {
                        display: flex;
                        align-items: flex-end;
                        position: absolute;
                        bottom: 0;
                        height: 50%;
                        width: 100%;
                        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));

                        p {
                            font-size: 18px;
                            color: $white;
                            margin: 0 0 2rem 2rem;

                            @media screen and (max-width: $desktop-lower-width) {
                                font-size: 14px;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                right: 2rem;
                                bottom: 2rem;
                                background-image: url('../images/circle_arrow.svg');
                                color: $white;
                                width: 36px;
                                height: 36px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &#about-us {
        position: relative;

        .bios-row {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            .flex-36 {
                width: 36%;

                @media screen and (max-width: $tablet-upper-width) {
                    width: 100%;
                }
            }

            @media screen and (max-width: $tablet-upper-width) {
                flex-direction: column;
            }

            p {
                @media screen and (max-width: $tablet-upper-width) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .join-team-wide {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-left: 2rem;
                padding-top: 3.5rem;

                p:nth-child(1) {
                    font-family: $font-muli;
                    margin-bottom: 0;
                    font-weight: 900;
                    font-size: 24px;
                    //add in line height to make smaller
                }

                p:nth-child(2) {
                    margin-top: 0;
                    font-size: 22px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    background-image: url('../images/Careers_callout.svg');
                    background-repeat: no-repeat;
                    top: 20;
                    left: -60;
                    width: 127px;
                    height: 114px;
                    z-index: -1;
                }

                @media screen and (max-width: $tablet-upper-width) {
                    display: none;
                }
            }

            .bios {
                width: 55%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 63px;
                justify-content: flex-end;

                @media screen and (max-width: $tablet-upper-width) {
                    width: 100%;
                    margin-top: 2rem;
                }

                .bio-item {
                    text-align: center;
                    width: 33%;
                    padding: .5rem;

                    @media screen and (max-width: $desktop-middle-width) {
                        width: 33%;
                    }

                    @media screen and (max-width: $desktop-lower-width) {
                        width: 33%;
                    }

                    @media screen and (max-width: $tablet-lower-width) {
                        width: 33%;
                    }

                    @media screen and (max-width: $mobile-upper-width) {
                        width: 50%;
                    }

                    @media screen and (max-width: 374px) {
                        width: 100%;
                    }

                    .bio-image {
                        overflow: hidden;
                        border-radius: 100%;
                        margin: 0 auto;
                        width: 120px;

                        @media screen and (min-width: $desktop-upper-width) {
                            width: 140px;
                        }

                        img {
                            width: 120px;

                            @media screen and (min-width: $desktop-upper-width) {
                                width: 140px;
                            }

                            @media screen and (min-width: $mobile-upper-width) {
                                cursor: pointer;
                                transition: all 300ms ease-in-out;


                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }

                    .p-name {
                        font-family: $font-muli;
                        font-size: 15px;
                        color: $athleteReg;
                        font-weight: 900;
                        margin-bottom: 0;
                        padding-top: 0.5em;
                    }

                    .p-title {
                        font-size: 15px;
                        color: $athleteReg;
                        font-weight: 400;
                        margin-top: 0;
                    }
                }
            }
        }

        .join-team-mobile {
            display: none;
            position: relative;
            width: 100%;
            padding-left: 4rem;
            padding-top: 3.5rem;

            p:nth-child(1) {
                font-family: $font-muli;
                margin-bottom: 0;
                font-weight: 900;
            }

            p:nth-child(2) {
                margin-top: 0;
            }

            &:before {
                content: '';
                position: absolute;
                background-image: url('../images/Careers_callout.svg');
                background-repeat: no-repeat;
                background-size: 60%;
                top: 35;
                left: 8;
                width: 127px;
                height: 114px;
                z-index: -1;
            }

            @media screen and (max-width: $tablet-upper-width) {
                display: block;
            }
        }
    }
}

    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;

        .flex-40 {
            width: 40%;

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
            }
        }

        .flex-45 {
            width: 45%;

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
            }
        }

        .flex-60 {
            width: 60%;

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
            }
        }

        .flex-62 {
            width: 90%;

            @media screen and (max-width: $mobile-upper-width) {
                width: 100%;
            }
        }

        .margin-push {
            margin-top: 63px;

            @media screen and (max-width: $mobile-upper-width) {
                margin-top: auto;
            }
        }

        @media screen and (max-width: $mobile-upper-width) {
            flex-direction: column;
        }

        .column-image img {
            width: 100%;
        }
    }

    hr {
        margin: 2rem 0;
        background-color: $accent;
        color: $accent;

        @media screen and (max-width: $mobile-upper-width) {
            display: none;
        }
    }



a {
    text-decoration: none;
    color: $bikeRegLinkBlue;
    cursor: pointer;
}

p {
  font-family: $font-sans;
  font-size: 17px;
  line-height: 1.58;
  letter-spacing: 0.005;
  margin: 0;
  @media screen and (min-width: $desktop-middle-upper-width) {
    font-size: 17px;
  }
  @media screen and (min-width: $desktop-upper-width) {
    font-size: 24px;
  }
  @media screen and (max-width: $desktop-middle-width) {
    font-size: 17px;
  }
  @media screen and (max-width: $tablet-lower-width) {
    font-size: 15px;
  }
  &.p-small {
    font-family: $font-sans;
    font-size: 15px;
    line-height: 1.66;
    letter-spacing: 0.005;
    max-width: 100%;
  }
  &.quote {
    font-style: italic;
    font-size: 13px;
    line-height: 1.69;
    @media screen and (min-width: $desktop-middle-width) {
      font-size: 15px;
      line-height: 1.6;
    }
    @media screen and (min-width: $desktop-middle-upper-width) {
      font-size: 17px;
      line-height: 1.65;
    }
  }
  &.testimonials-p {
    font-weight: 800;
    font-size: 15px;
    color: $athleteReg;
    margin: 0;
    display: block;
  }
}

h1 {
  font-family: $font-muli;
  //font-style: italic;
  color: $white;
  font-weight: 900;
  font-size: 48px;
  line-height: 1;
  @media screen and (min-width: $desktop-lower-width) {
    font-size: 42px;
    line-height: 1;
  }
  @media screen and (min-width: $desktop-middle-width) {
    font-size: 50px;
    line-height: 1.34;
  }
  @media screen and (min-width: $desktop-middle-upper-width) {
    font-size: 60px;
    line-height: 1.26;
  }
  @media screen and (min-width: $desktop-upper-width) {
    font-size: 70px;
    line-height: 1.26;
  }
  @media screen and (max-width: $mobile-upper-width) {
    font-size: 46px;
  }
  @media screen and (max-width: $mobile-lower-width) {
    font-size: 40px;
  }
}

h2 {
    margin: 2rem 0;
    font-family: $font-muli;
    font-weight: 900;
    font-size: 25px;
    line-height: 1.2;

    @media screen and (min-width: $tablet-lower-width) {
        font-size: 28px;
        line-height: 1.14;
    }

    @media screen and (min-width: 1025px) {
        font-size: 42px;
        line-height: 1.09;
    }

    @media screen and (min-width: $desktop-upper-width) {
        font-size: 50px;
        line-height: 1.16;
    }

    @media screen and (max-width: $tablet-lower-width) {
        margin: 1.5rem 0;
    }
}

h3:not(.react *) {
    font-family: $font-muli;
    font-size: 12px;
    letter-spacing: 0.2em;
    line-height: 21px;
    margin-left: -15px;
    text-transform: uppercase;
    margin-bottom: -15px;

    &:before {
        content: "";
        padding-left: 10px;
        display: inline-block;
        background-image: url("../images/h3_before.svg");
        height: 10px;
        width: 8px;
        background-repeat: no-repeat;
    }
}

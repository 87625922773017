html {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  body {
    font-family: $font-sans;
    font-size: 15px;
    color: $athleteReg;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.005em;
    background-color: $white;
    margin: 0;
    width: 100%;

    &.no-scroll-mobile { 
      overflow: hidden;
    }
  }
}

* {
  box-sizing: inherit;
}

ul {
  list-style: none;
}

// Debugging ghost element

// * {
//     background: #000 !important;
//     color: #0f0 !important;
//     outline: solid #f00 1px !important;
//   }

.modal {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(78, 82, 90, 0.9);
    width: 0;
    height: 100%;
    z-index: 10001;
    overflow: hidden;
    transition: 300ms;

    &.show-modal {
        display: flex;
        opacity: 1;
        width: 100%;
    }

    &__box {
        display: none;
        flex-direction: row;
        background: $white;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1000px;
        max-width: 100%;
        height: 500px;
        max-height: 100%;

        @media screen and (max-width: $mobile-upper-width) {
            flex-direction: column-reverse;
            height: 100%;
            width: 100%;
        }
    }

    .show-bio {
        display: flex;
    }

    &--main {
        @media screen and (max-width: $mobile-upper-width) {
            height: 90%;
            width: 90%;
        }
    }

    &__image {
        width: 40%;
        height: 100%;
        overflow: hidden;

        @media screen and (max-width: $mobile-upper-width) {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            @media screen and (max-width: $mobile-upper-width) {
                object-position: top;
            }
        }
    }
    //Brandon's image needs to be left aligned.
    &--brandon {
        .modal__image {
            img {
                object-position: left;
            }
        }
    }

    &__description {
        font-family: $font-sans;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: left;
        padding: 5em;
        background: $white;
        width: 60%;
        height: 100%;

        @media screen and (max-width: $mobile-upper-width) {
            width: 100%;
            height: 100%;
            padding: 1em;
        }

        .modal__title {
            font-size: 24px;
            font-weight: 900;
            margin: 0;

            @media screen and (max-width: $mobile-upper-width) {
                font-size: 20px;
            }
        }

        .modal__sub-title {
            margin: 0;
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 1em;

            @media screen and (max-width: $mobile-upper-width) {
                font-size: 14px;
            }
        }

        .modal__specs {
            margin: 1em 0;
            font-size: 17px;
            font-weight: 400;

            @media screen and (max-width: $mobile-upper-width) {
                font-size: 14px;
            }
        }
    }

    &__close {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        padding: 1em;

        img {
            height: 14px;
            width: 14px;
        }
    }
}

// Project Colors
$white: #ffffff;
$opaqueWhite: rgba(255,255,255,0.9);
$black: #000000;
$darkestGray: #272d31;
$athleteReg: #303e48;
$tan: rgba(247, 191, 140, 0.22);
$flatGray: rgba(181, 175, 177, 0.22);
$slateGray: rgba(77, 114, 124, 0.22);
$SixteenPercentBlack: rgba(0, 0, 0, 0.16);
$athleteRegSecondary: #4e525a;
$darkBorder: #707070;
$pledgeRegOrange: #e0692b;
$accent: #c1cad0;
$thirtyFivePercentBlack: rgba(0, 0, 0, 0.35);
$bikeRegBlue: #0e4da7;
$bikeRegLinkBlue: #3876d0;
$runRegGreen: #94d60a;
$transparentGreen: rgba(148, 214, 10, 0.45);
$triRegPink: #ec0044;
$skiRegBlue: #67a2b9;
$midGray: #d1d1d1;
$blueGray: #c0cad0;
$midGray2: #cacaca;
$formTextGray: #525252;
$lightGray: #f2f2f2;

//Font
$font-sans: "Open Sans", arial, helvetica, sans-serif;
$font-muli: "Muli", "trebuchet ms", arial, helvetica, sans-serif;

// Breakpoints
$mobile-lower-width: 320px;
$mobile-upper-width: 767px;
$tablet-lower-width: 768px;
$tablet-middle-width: 991px;
$tablet-upper-width: 1023px;
$desktop-lower-width: 1024px; // Including iPad Pro
$desktop-middle-width: 1280px;
$desktop-middle-width2: 1281px;
$desktop-middle-lower-width: 1460px;
$desktop-middle-upper-width: 1600px;
$desktop-upper-width: 1920px;

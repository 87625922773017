@use "sass:math";

@import "_variables.scss";

@mixin transition($seconds) {
    transition: all $seconds ease-in-out;
}

/* to use: @include transition(0.5s); - remember goes on parent not hover state */

/* Calculate Font Size ------------------------------------------- */
@function strip-unit($value) {
	@return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			// Mobile
			@media screen and (min-width: $min-vw) {
				font-size: calc( #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}) );
			}
			// Desktop
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

// Desired Desktop Font Size
@mixin get-fluid-font-size($desired-font-size) {
	/*  @include this bootstrapped mixin to get an appropriate font size.
	  In sum, enter the desired pixel value you would like it to be, and
	  a viewport appropriate equivilant value will be returned and used.

	  See Calculate Font Size for variable definitions.
  */
	@include fluid-type( $min-scale-width, $max-scale-width, $desired-font-size - $font-offset, $desired-font-size );
}

/* Scale Image to View:
	Min effective view width, max effective view width, min allowed image size, max allowed image size
	These variables aren't as global as fluid-type so we don't have a bootstrapped mixin for it; image requirements often change.
	WARNING: The image must be inside a container for scaling to work as intended.
*/
@mixin fluid-image-width-container($min-vw, $max-vw, $min-image-size, $max-image-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-image-size);
	$u4: unit($max-image-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			width: $min-image-size;
			// Mobile
			@media screen and (min-width: $min-vw) {
				width: calc( #{$min-image-size} + #{strip-unit($max-image-size - $min-image-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}) );
			}
			// Desktop
			@media screen and (min-width: $max-vw) {
				width: $max-image-size;
			}
		}
	}
}

/* Select All If Certain Amount ----------------------------*/
/* If you want to style items differently if there’s a certain amount
  (such as making them smaller if there’s at least five), use the below mixin.

Example:
li {
  display: block;

  @include at-least(5, "li") {
	display: inline-block;
  }
}
*/
@mixin at-least($min, $element) {
	// @error "Min: '#{min}' Element:`#{$element}`";

	&:first-child:nth-last-child(n + #{$min}),
	&:first-child:nth-last-child(n + #{$min}) ~ #{$element} {
		@content;
	}
}

/* Links */
@mixin links($link, $visited, $hover, $focus, $active, $weight) {
	&:link {
		color: $link;
		text-decoration: none;
		font-weight: $weight;
	}

	&:visited {
		color: $visited;
	}

	&:hover {
		color: $hover;
		text-decoration: underline;
	}

	&.expandcollapse:hover {
		color: $hover;
		text-decoration: none;
	}

	&:focus {
		color: $focus;
	}

	&:active {
		color: $active;
	}
	/* could also just use opacity for hover states

	&:hover {opacity: 0.9;}
	&:focus {opacity: 0.9;}
	&:active {opacity: 0.9;}

	*/
}

/* Box Shadow Mixin ---------------------------------------------- */
/* To use: @include box-shadow($top, $left, $blur, $spread, $color); */
$top: 0px;
$left: 0px;
$blur: 1px;
$spread: 0px;
$color: rgba(0, 0, 0, 0.4);

@mixin box-shadow($top, $left, $blur, $spread, $color) {
	box-shadow: $top $left $blur $spread $color;
	/* Firefox */
	@-moz-document url-prefix() {
		box-shadow: $top $left 2px $spread $color;
	}
	/* IE10+ */
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		box-shadow: none;
		border: 1px solid $ltgray2; // IE11 doesn't seem to handle box shadows well.
	}
}

/* Transitions */
/* Extend on default, non-selected classes. */
.simple-in-out-transition {
	transition: all 0.1s ease-in-out;
}

/* Extend on default, non-selected classes. */
.staggered-in-transition {
	transition: background-color 0.1s ease;
	transition: color 0.5s ease;
}

/* Extend on hovered/selected classes. */
.staggered-out-transition {
	transition: background-color 0 ease;
	transition: color 0 ease;
}

// Pop out an option on hover.
.transform-pop-transition {
    transform: scale(1); /* Scale here to allow it to transition in both directions */
    transition: 0.15s all ease;

    &:hover {
        transform: scale(1.2);
    }
}

/* Focus */

@mixin inputwhitefocus() {
	background: $white;
	color: $nearblack;
	border: 2px solid $white;
}

@mixin inputwhite() {
	background-color: $white;
	border: 2px solid $ltblue;
	color: $nearblack;
	@include border-radius(7px, 7px, 7px, 7px);
}

@mixin box-sizing {
	box-sizing: border-box;
}

@mixin border-radius($topright, $bottomright, $bottomleft, $topleft) {
	border-radius: $topright $bottomright $bottomleft $topleft;
}

// This section was styled using the BEM (Block Element Modifier) methodology.
// BEM naming structure is: block__element--modifier
// Info on this can be found here: https://seesparkbox.com/foundry/bem_by_example

footer {
    background: $athleteReg;
    padding: 6em 0 3em 0;
    color: $white;

    .footer {
        &--flex {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: $desktop-middle-width) {
                flex-direction: column;
            }

            .paragraph {
                &--stacked {
                    :first-child {
                        margin-bottom: 0;
                    }

                    :last-child {
                        margin-top: 0;
                    }
                }

                &__title {
                    font-family: $font-muli;
                    font-weight: 700;
                    color: $accent;
                    font-size: 16px;
                    letter-spacing: 1.5;
                    text-transform: uppercase;
                }
            }

            .newsletter {


                @media screen and (max-width: $desktop-middle-width) {
                    width: 100%;
                    padding-bottom: 3em;
                    margin-bottom: 3em;
                    border-bottom: 1px solid $accent;
                }

                @media screen and (max-width: $mobile-upper-width) {
                    width: 100%;
                    padding-bottom: 2em;
                    margin-bottom: 2em;
                    border-bottom: none;
                }

                &__form {
                    width: 610px;
                    display: flex;
                    margin-top: 1.5em;
                    flex-direction: column;

                    @media screen and (max-width: $desktop-lower-width) {
                        width: 100%;
                    }

                    &--name {
                        width: 100%;
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: row;

                        :first-child {
                            margin-right: 10px;

                            @media screen and (max-width: $mobile-upper-width) {
                                margin-right: 0px;
                                margin-bottom: 10px;
                            }
                        }

                        @media screen and (max-width: $mobile-upper-width) {
                            flex-direction: column;
                        }
                    }

                    input {
                        height: 50px;
                        font-size: 17px;
                        font-family: $font-sans;
                        color: $formTextGray;
                        padding: 14px 18px 13px 18px;
                    }

                    input[type="text"] {
                        width: 300px;

                        @media screen and (max-width: 1730px) {
                            width: 215px;
                        }

                        @media screen and (max-width: $desktop-middle-width) {
                            width: 300px;
                        }

                        @media screen and (max-width: $mobile-upper-width) {
                            width: 100%;
                        }
                    }

                    input[type="email"] {
                        width: 100%;

                        @media screen and (max-width: 1730px) {
                            width: 440px;
                        }

                        @media screen and (max-width: $desktop-middle-width) {
                            width: 610px;
                        }

                        @media screen and (max-width: $mobile-upper-width) {
                            width: 100%;
                        }
                    }

                    .recaptcha-parent {
                        margin-top: 13px;
                        margin-bottom: 13px;
                    }

                    input[type="button"] {
                        width: 191px;
                        height: auto;
                        color: $white;
                        text-transform: uppercase;
                        font-family: $font-muli;
                        font-size: 15px;
                        font-weight: 200;
                        letter-spacing: 1.5;
                        padding: 0.634em 0;
                        background: transparent;
                        border: 1px solid $white;
                        cursor: pointer;
                        transition: all 300ms ease-in-out;
                        margin-top: 13px;

                        &:hover {
                            background: $white;
                            color: $athleteReg;
                            font-weight: 700;
                        }
                    }
                }
            }

            .info {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                @media screen and (max-width: $mobile-upper-width) {
                    flex-direction: column;
                }

                &__contact-us {
                    width: 40%;

                    @media screen and (max-width: $desktop-lower-width) {
                        width: 100%;
                    }

                    @media screen and (max-width: $mobile-upper-width) {
                        padding-bottom: 2em;
                        margin-bottom: 2em;
                        border-bottom: 1px solid $accent;
                    }

                    .paragraph__email-phone,
                    .paragraph__address {
                        margin: 1.5em 0;

                        p {
                            font-size: 17px;

                            a {
                                color: $white;
                            }
                        }
                    }
                }

                .follow-us {
                    width: 30%;

                    @media screen and (max-width: $desktop-lower-width) {
                        width: 100%;
                    }

                    > * {
                        margin-bottom: 2em;
                    }

                    a:first-child {
                        padding-right: 1em;
                    }

                    .paragraph__title {
                        margin-bottom: 1em;
                    }

                    &__copyright {
                        p {
                            font-size: 14px;

                            @media screen and (max-width: $mobile-upper-width) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .disclaimer {
            margin-top: 0.5em;
            font-weight: normal;
        }
    }

    .outside-links {
        a {
            color: $runRegGreen;
        }

        .delimiter {
            padding: 0.5em;
            font-size: 14px;
        }

        @media screen and (max-width: $mobile-upper-width) { //hide the delimiters on mobile resolutions
            flex-direction: column;
            align-items: flex-end;

            .delimiter {
                display: none;
            }
        }

        margin: 1em 2em 3em 2em;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;

        .outside-link {
            @media screen and (max-width: $mobile-upper-width) {
                flex: 50%;
            }

            font-size: calc-em(14,16); //make same size as xreg footer links
        }

        a {
            text-align: center;
        }

        .cmp-revoke-consent {
            position: unset;
            padding: unset;
        }
    }

    .outside-row {
        margin: 0 2em 0 2em;
        font-size: calc-em(16,16);

        @media screen and (max-width: 768px) {
            margin: auto;
            width: 90%;
            font-size: calc-em(12,16);
        }

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .outside-logo {
            max-width: 125px;

            @media screen and (max-width: 768px) {
                max-width: 78px;
            }
        }

        .o-logo {
            max-width: 31px;

            @media screen and (max-width: 768px) {
                max-width: 19px;
            }
        }

        .legal {
            margin-left: auto;
            margin-right: 1em;
        }

        .cmp-revoke-consent {
            position: unset;
        }
    }
}

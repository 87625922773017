@import "../settings/_settings.scss";

button, .button {
    font-family: $font-muli;
    font-weight: 600;
    font-size: 1.2em;
    letter-spacing: 0.05em;
    line-height: 28px;
    background: none;
    color: $athleteReg;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
        outline: 0;
    }

    &.button-visit {
        border: 1px solid $darkBorder;
        padding: 0.34em 1.6em;
        font-weight: 400;
        font-size: 15px;

        @media screen and (max-width: $desktop-middle-width) {
            font-size: 14px;
        }

        &:hover {
            background-color: $athleteReg;
            color: $white;
            font-weight: 400;
        }
    }

    &.button-manage {
        display: block;
        color: $white;
        padding-left: 50px;
        margin: 0.5em 0;
        text-align: left;
        font-size: 1em;
        font-weight: 400;
        border: 1px solid $accent;
        width: 100%;
        height: 64px;
        position: relative;

        @media screen and (max-width: $tablet-upper-width) {
            padding-left: 30px;
            font-size: 14px;
            font-weight: 400;
        }

        &:hover,
        :active {
            background-color: $thirtyFivePercentBlack;
        }

        &.bike-accent:before {
            background-color: $bikeRegBlue;
        }

        &.run-accent:before {
            background-color: $runRegGreen;
        }

        &.tri-accent:before {
            background-color: $triRegPink;
        }

        &.ski-accent:before {
            background-color: $skiRegBlue;
        }

        &.pledge-accent:before {
            background-color: $pledgeRegOrange;
        }

        &:before {
            content: "";
            width: 15px;
            height: 54px;
            position: absolute;
            left: 5px;
            top: 5px;
        }

        &:after {
            content: "";
            position: absolute;
            right: 28px;
            bottom: 13px;
            background-image: url("../images/circle_arrow.svg");
            color: $white;
            width: 36px;
            height: 36px;

            @media screen and (max-width: $tablet-upper-width) {
                display: none;
            }
        }
    }

    &.button-link {
        border: none;
        color: $bikeRegLinkBlue;
        padding: 0;
        margin: 0;
        font-weight: 600;

        @media screen and (max-width: $mobile-upper-width) {
            font-size: 18px;
        }

        @media screen and (max-width: $desktop-middle-width) {
            font-size: 14px;
        }
    }

    &.scroll-down {
        border: none;
        color: $athleteReg;
        font-size: 12px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        &:before {
            content: "";
            display: inline-block;
            background-image: url("../images/circle_arrow_dark.svg");
            background-repeat: no-repeat;
            width: 36px;
            height: 36px;
            transform: rotate(90deg);
            margin-right: 10px;
        }
    }

    &.button-form {
        transition: all 300ms ease-in-out;
        padding: 0.5em 3em 0.5em 3em;
        background-color: $blue2;
        color: $white;
        border: 2px solid $blue2;
        text-decoration: none;
        white-space: nowrap;
        text-align: center;
        display: inline-block;

        &:hover {
            background-color: $white;
            color: $blue2;
        }
    }
}
